import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import ThemeChartEla from 'components/themes/ThemeChart/ThemeChartEla'
import ThemeChartGenre from 'components/themes/ThemeChart/ThemeChartGenre'
import ThemeChartResearchLab from 'components/themes/ThemeChart/ThemeChartResearchLab'
import ThemeChartSpanish from 'components/themes/ThemeChart/ThemeChartSpanish'
import ThemeChartSpanishGenre from 'components/themes/ThemeChart/ThemeChartSpanishGenre'
import ThemeChartTextSets from 'components/themes/ThemeChart/ThemeChartTextSets'
import { IThemeCard } from 'components/themes/Themes'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

export default function AllThemesPage() {
	const queryData = useStaticQuery<ThemeCardQuery>(query).allContentfulTheme.edges

	const [isClient, setIsClient] = useState(false)

	useEffect(() => {
		setIsClient(true)
	}, [])

	return (
		<Layout pageName="Themes">
			<Section margin="mt-20">
				<Row>
					<Link to="/themes">
						<div className="flex items-center gap-1">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
							</svg>
							Back to Themes
						</div>
					</Link>
				</Row>
				<Row>
					<Col gutter="none">
						<h4 className="mt-10">Science & Social Studies Themes</h4>
						<div className="overflow-x-auto">
							<ThemeChartResearchLab themes={queryData} />
						</div>
					</Col>
				</Row>
				<Row>
					<Col gutter="none">
						<h4 className="mt-10">Spanish Science & Social Studies Themes</h4>
						<div className="overflow-x-auto">
							<ThemeChartSpanish themes={queryData} />
						</div>
					</Col>
				</Row>
				<Row>
					<Col gutter="none">
						<h4 className="mt-10">Spanish Literature Genre Studies</h4>
						<div className="overflow-x-auto">
							<ThemeChartSpanishGenre />
						</div>
					</Col>
				</Row>
				<Row>
					<Col gutter="none">
						<h4 className="mt-10">Literature Genre Studies</h4>
						<div className="overflow-x-auto">
							<ThemeChartGenre />
						</div>
					</Col>
				</Row>
				<Row>
					<Col gutter="none">
						<h4 className="mt-10">Genre Text Sets</h4>
						<div className="overflow-x-auto">
							<ThemeChartEla />
						</div>
					</Col>
				</Row>
				<Row>
					<Col gutter="none">
						<h4 className="mt-10">Additional Text Sets</h4>
						<div className="overflow-x-auto">
							<ThemeChartTextSets themes={queryData} />
						</div>
					</Col>
				</Row>
			</Section>
			{isClient && (
				<ReactTooltip effect="solid" backgroundColor="#fff" textColor="#304157" borderColor="#E5E7EB" border className="max-w-xs text-center !text-base !opacity-100" />
			)}
		</Layout>
	)
}

export interface ThemeCardQueryNode {
	node: IThemeCard
}

interface ThemeCardQuery {
	allContentfulTheme: {
		edges: ThemeCardQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulTheme(sort: { fields: [name], order: ASC }, filter: { removeInThemePagesBuild: { ne: true } }) {
			edges {
				node {
					id
					name
					slug
					themeType
					themeSubject
					gradeLevel
					grades
					eLibrary
					libraryDisclaimer
					digitalResources
					hasResearchLab
					basketLevels
					schoolPaceConnectGrades
					description {
						description
					}
					coverImage {
						gatsbyImageData
					}
				}
			}
		}
	}
`
